
import { Component, Vue, Watch } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import {
  getCouponCodeList,
  initCouponCodeData,
  GiftPackCodeItem,
  createCouponCode,
  exportCouponCodeData,
} from '@/api/marketing/coupon';
import { PaginationData } from '@/api/operation/customer';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
const RECEIVE_STATUS = [
  {
    value: 2,
    label: '全部',
  },
  {
    value: '0',
    label: '未领取',
  },
  {
    value: 1,
    label: '已领取',
  },
];
const USE_STATUS = [
  {
    value: 2,
    label: '全部',
  },
  {
    value: '0',
    label: '未使用',
  },
  {
    value: 1,
    label: '已使用',
  },
];
@Component
export default class CouponCode extends Vue {
  setGlobalFunction=useGlobalFunction().setGlobalFunction;
  is_loading: boolean = false;
  is_show_create_modal: boolean = false;
  package_name: any = '';
  created_number: number = 10;
  form_value: any = {
    code: '',
    receive_status: 2,
    use_status: 2,
  };
  coupon_code_data: PaginationData<GiftPackCodeItem> = initCouponCodeData();
  get receiveStatus() {
    return RECEIVE_STATUS;
  }
  get useStatus() {
    return USE_STATUS;
  }

  validateMap: any = {
    numbers: {
      status: '',
      help: '',
      tip_text: '请输入正整数',
    },
  }
  //生成卷码数量验证
  //@Watch('created_number')
  isSub: boolean=true
  changeCreatedNumber() {
    const reg = /^[1-9]\d*$/;
    if(!reg.test(String(this.created_number))){
      this.validateMap.numbers.status= 'error'
      this.validateMap.numbers.help= '请输入正整数'
      this.isSub=false
    }else{
        this.validateMap.numbers.help = ''
        this.validateMap.numbers.status = '';
        this.isSub=true
      }
    //this.created_number = this.created_number < 1 ? 1 : this.created_number;
  }
  // 获取优惠券券码
  @changeLoading(['is_loading'])
  async getCouponCodeList(query_params?: any) {
    const res = await getCouponCodeList({
      page: 50,
      id: this.$route.query.id,
      ...query_params,
    });
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.coupon_code_data = initCouponCodeData(res.data);
    //console.log(this.coupon_code_data)
  }

  // 关闭模态窗
  closeModal() {
    this.created_number = 10;
    this.is_show_create_modal = false;
  }

  // 导出
  code: any
  receive_status: any
  use_status: any
  exportData() {
    if(this.coupon_code_data.detail.length==0){
      this.$message.error('暂无数据，导出失败')
    }else{
      /* this.code=this.form_value.code
      this.receive_status=this.form_value.receive_status
      this.use_status=this.form_value.use_status */
      exportCouponCodeData(
      this.code,
      this.receive_status === 2 ? '' : this.receive_status,
      this.use_status === 2 ? '' : this.use_status,
      +this.$route.query.id
    );
    }
    
  }

  paginationOption(data: any) {
    return {
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      total: data.total || 0,
      pageSize: data.limit || 1,
      current: +data.current || 1,
    };
  }

  // 清空搜索条件
  clearForm() {
    this.form_value = {
      code: '',
      receive_status: 2,
      use_status: 2,
    };
  }

  // 切换分页
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    await this.getCouponCodeList({
      current: data.current,
      page: 50,
      id: this.$route.query.id,
      code: this.form_value.code,
      get_status: this.form_value.receive_status === 2 ? '' : this.form_value.receive_status,
      use_status: this.form_value.use_status === 2 ? '' : this.form_value.use_status,
    });
  }

  // 生成券码
  @changeLoading(['is_loading'])
  async createCode() {
    if(this.isSub){
      const res = await createCouponCode(+this.$route.query.id, this.created_number);
      if (res.status !== 200) {
        this.$message.error(this.package_name+(res as any).message);
        //this.$message.error(`${this.package_name} 不通过券码兑换形式发放，无法生成券码`);
        return this.closeModal();
    }
      await this.getCouponCodeList();
      this.closeModal();
      this.$message.success('生成成功');
    }
    
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async searchBtn() {
    this.code=this.form_value.code
    this.receive_status=this.form_value.receive_status
    this.use_status=this.form_value.use_status
    await this.getCouponCodeList({
      code: this.form_value.code,
      get_status: this.form_value.receive_status === 2 ? '' : this.form_value.receive_status,
      use_status: this.form_value.use_status === 2 ? '' : this.form_value.use_status,
    });
  }

  @changeLoading(['is_loading'])
  async created() {
    this.package_name = this.$route.query.coupon_name;
    await this.getCouponCodeList();
    await this.setGlobalFunction({getCouponCodeList:this.getCouponCodeList})
  }
}
